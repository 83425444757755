import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const cache = {}; // Cache object to store tracking IDs by slug

const useMarketingIntegrations = () => {
    const location = useLocation();
    const [trackingIds, setTrackingIds] = useState({ googleAnalyticsId: null, metaPixelId: null, googleTagManagerId: null, tiktokPixelId: null });

    useEffect(() => {
        const fetchTrackingIds = async () => {
            const splitUrl = location.pathname.split("/");
            let slug = splitUrl[2];
            if (splitUrl[1] === "iframe") slug = splitUrl[3];

            if (!slug) {
                console.log("No seller slug detected - Tracking disabled.");
                setTrackingIds({ googleAnalyticsId: null, metaPixelId: null, googleTagManagerId: null, tiktokPixelId: null });
                return;
            }

            if (cache[slug]) {
                console.log("Using cached tracking IDs for slug:", slug);
                setTrackingIds(cache[slug]);
                return;
            }

            try {
                const res = await axios.get(`${process.env.REACT_APP_API}/partners/search/marketingIntegrations/${slug}`);

                if (res.data.success) {
                    const { googleAnalyticsId, metaPixelId, googleTagManagerId, tiktokPixelId } = res.data.data;
                    console.log("Tracking IDs fetched:", { googleAnalyticsId, metaPixelId, googleTagManagerId, tiktokPixelId });
                    const ids = { googleAnalyticsId, metaPixelId, googleTagManagerId, tiktokPixelId };
                    cache[slug] = ids; // Cache the fetched IDs
                    setTrackingIds(ids);
                } else {
                    console.log("No tracking setup for this seller.");
                    setTrackingIds({ googleAnalyticsId: null, metaPixelId: null, googleTagManagerId: null, tiktokPixelId: null });
                }
            } catch (error) {
                console.error("Error fetching tracking IDs", error);
                setTrackingIds({ googleAnalyticsId: null, metaPixelId: null, googleTagManagerId: null, tiktokPixelId: null });
            }
        };

        fetchTrackingIds();
    }, [location.pathname]);

    return trackingIds;
};

export default useMarketingIntegrations;
