import React from "react";
import { promotion_discount, calculateProratedClasses } from "../../../pages/front/Checkout/utils";

const SummarySubTotal = ({
  selectedPlan,
  /*deprecated:*/ plans,
  /*deprecated:*/ selectedPlanId,
  dropIns,
  installments,
  quantity,
  isPromo,
  promotion,
  program
}) => {
  const displayAmount = () => {
    //using "plans" and "selectedPlanId" is not preferred! Just send the damn plan through "selectedPlan"!
    if (!selectedPlan && plans?.length > 0 && selectedPlanId >= 0) {
      const foundPlan = plans.find(e => JSON.parse(e).id === selectedPlanId);
      selectedPlan = foundPlan ? JSON.parse(foundPlan) : null;
    }
    if (!selectedPlan) return null;

    console.log("selectedPlan", selectedPlan);
    console.log("promotion", promotion);

    if (!selectedPlan.type) {
      if (isPromo) {
        console.log(
          "promotion_discount(promotion, selectedPlan.total_price)",
          promotion_discount(promotion, selectedPlan.tuition_rate)
        );
        return Math.max(
          0,
          parseFloat(selectedPlan.tuition_rate) -
            promotion_discount(promotion, selectedPlan.tuition_rate)
        ).toFixed(2);
      }

      return selectedPlan.tuition_rate;
    }
    if (selectedPlan.type === "Single Sessions") {
      if (isPromo) {
        return Math.max(
          0,
          selectedPlan.total_price * quantity -
            promotion_discount(promotion, selectedPlan.total_price) * quantity
        ).toFixed(2);
      }
      return selectedPlan.total_price * quantity;
    }
    if (selectedPlan.type === "Free" || selectedPlan.type === "Free Trial") return selectedPlan.total_price;
    if (selectedPlan.type === "Semester" || selectedPlan.type === "One-Time" || selectedPlan.type === "Drop-In") {
      let total = selectedPlan.total_price;
      if (selectedPlan.isProrated) {
        let { amount } = calculateProratedClasses(program, selectedPlan.total_price);
        if (!Number.isInteger(amount)) {
          amount = amount.toFixed(2);
        }
        total = amount;
      }
      if (isPromo) {
        return Math.max(
          0,
          total -
            promotion_discount(promotion, total)
        ).toFixed(2);
      }

      return total;
    }
    if (selectedPlan.type === "Semester" && selectedPlan.installments) {
      return parseFloat(
        (selectedPlan.total_price / selectedPlan.installments_breakdown) *
          (1 + parseInt(selectedPlan.installments_interest) / 100)
      ).toFixed(0);
    }

    return parseFloat(
      (selectedPlan.total_price / selectedPlan.dayCount) *
        (1 + parseInt(selectedPlan.dropin_interest) / 100)
    ).toFixed(0);
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flexStart",
          fontWeight: 600,
          color: "#828282",
          fontFamily: "Open Sans"
        }}
      >
        SUMMARY
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "7px",
          color: "darkslategrey",
          fontWeight: 600
        }}
      >
        <div>Subtotal</div>
        <div>${displayAmount()}</div>
      </div>
    </div>
  );
};

export default SummarySubTotal;
