import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  promotion_discount,
  coupon_discount,
  getSelectedProgramPlan,
  calculateProratedClasses
} from "./utils";

const TaxableProgramRegularSemester = props => {
  const [taxName, setTaxName] = useState("Loading");
  const [taxAmount, setTaxAmount] = useState("Loading");
  console.log("line8", props);

  const calculateTaxAmount = (plan, props) => {
    let total_price = parseFloat(plan.total_price);
    if (plan.isProrated) {
      const { totalSessions, sessionsPassed, amount } = calculateProratedClasses(props.program, plan.total_price);
      total_price = amount;
    }
    if (plan.addOnItems) {
      plan.addOnItems.map(item => {
        total_price += parseFloat(item.value);
      });
    }
    if (props.installments) {
      const tot = parseFloat(
        parseFloat(
          total_price *
            (props.installments
              ? 1 + parseInt(plan.installments_interest) / 100
              : 1) || 0
        ) *
          (plan.taxInfo[0].percentage / 100)
      ).toFixed(2);
      props.taxAmount(tot.toFixed(2));
      setTaxAmount(tot);
      return;
    } else {
      // no installment
      let tot = 0;
      if (props.promotion) {
        const discount_amount = promotion_discount(
          props.promotion,
          total_price
        );
        tot =
          Math.max(0.5, total_price) *
          (parseFloat(plan.taxInfo[0].percentage) / 100);
      } else {
        console.log("line 44", props.coupon);
        const discount_amount = props.coupon
          ? coupon_discount(props.coupon, parseFloat(total_price))
          : 0;
        console.log("line 47", discount_amount);
        console.log("line 48", total_price);
        tot =
          Math.max(0, total_price - discount_amount) *
          (parseFloat(plan.taxInfo[0].percentage) / 100);
      }

      props.taxAmount(tot.toFixed(2));

      setTaxAmount(tot.toFixed(2));
    }
  };
  const getTaxName = plan => {
    const displayName = plan.taxInfo[0].display_name;
    const percent = plan.taxInfo[0].percentage;
    setTaxName(`${displayName} on subtotal (${percent}%)`);
  };

  useEffect(() => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    calculateTaxAmount(plan, props);
    getTaxName(plan);
  }, [props.coupon]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        {taxName}
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${taxAmount}</span>
    </li>
  );
};

TaxableProgramRegularSemester.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  promotion: PropTypes.object,
  coupon: PropTypes.object
};

export default TaxableProgramRegularSemester;
