import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "../../../assets/css/componentSpecificCss/checkout.css";

import {
  getSelectedProgramPlan,
  calculateProratedClasses,
  PERCENT,
  coupon_discount,
  PROP_PROGRAM,
  PROP_ONLINE,
  PROP_EVENT,
  PROP_MEMBERSHIP,
  PROP_PARTY,
  COUPON_FIXED_AMOUNT,
  SEMESTER_DROPINS,
  REGULAR_SEMESTER,
  INSTALLMENTS,
  SINGLE_SESSIONS
} from "./utils";

const CheckoutCouponDisplay = props => {
  console.log("CheckoutCouponDisplay", props);
  const [discountAmount, setDiscountAmount] = useState("Loading");
  const [tax, setTax] = useState(0);

  const calculateDiscountAmount = tax => {
    console.log("rpops", props);

    if (props.coupon.type === COUPON_FIXED_AMOUNT) {
      props.couponAmount(parseFloat(props.coupon.amount_off).toFixed(2));
      return setDiscountAmount(
        parseFloat(props.coupon.amount_off).toFixed(2)
      );
    } else if (props.coupon.type === PERCENT) {
      if (props.type === PROP_PROGRAM) {
        const selected_plan = getSelectedProgramPlan(
          props.program,
          props.selected
        );
        if (props.program_type === REGULAR_SEMESTER) {
          let total_price = parseFloat(selected_plan.total_price);
          if (selected_plan.isProrated) {
            const { totalSessions, sessionsPassed, amount } = calculateProratedClasses(props.program, selected_plan.total_price);
            total_price = amount;
          }
          if (selected_plan.addOnItems) {
            selected_plan.addOnItems.map(item => {
              total_price += parseFloat(item.value);
            });
          }
          const d_amnt = coupon_discount(props.coupon, total_price);

          props.couponAmount(d_amnt.toFixed(2));

          return setDiscountAmount(d_amnt.toFixed(2));
        } else if (props.program_type === SINGLE_SESSIONS) {
          const total_price =
            parseFloat(
              selected_plan.total_price * props.singleSessionQuantity
            ) + tax;
          console.log(
            "single sessions",
            selected_plan.total_price * props.singleSessionQuantity,
            tax
          );
          const d_amnt = coupon_discount(props.coupon, total_price);

          props.couponAmount(d_amnt.toFixed(2));

          return setDiscountAmount(d_amnt.toFixed(2));
        } else if (props.program_type === INSTALLMENTS) {
          const installments_breakdown = selected_plan.installments_breakdown;
          let total_price = parseFloat(selected_plan.total_price);
          if (selected_plan.addOnItems) {
            selected_plan.addOnItems.map(item => {
              total_price += parseFloat(item.value);
            });
          }
          const installments_interest = parseInt(
            selected_plan.installments_interest
          );

          // const amnt = parseFloat(
          //   parseFloat(
          //     (total_price / installments_breakdown) *
          //       (props.installments ? 1 + installments_interest / 100 : 1) || 0
          //   )
          // );

          const amnt = parseFloat(
            parseFloat(
              total_price *
              (props.installments ? 1 + installments_interest / 100 : 1) || 0
            )
          );

          let coupon_amnt;
          if (
            selected_plan.includeDeposit &&
            selected_plan.deposit &&
            selected_plan.deposit !== "0"
          ) {
            // coupon_amnt = coupon_discount(
            //   props.coupon,
            //   parseFloat(selected_plan.deposit)
            // );
            coupon_amnt = coupon_discount(props.coupon, amnt);
          } else {
            coupon_amnt = coupon_discount(props.coupon, amnt);
          }

          props.couponAmount(coupon_amnt.toFixed(2));
          return setDiscountAmount(coupon_amnt.toFixed(2));
        } else if (props.program_type === SEMESTER_DROPINS) {
          const amnt = parseFloat(
            selected_plan.total_price *
            props.selected_days_length
          ).toFixed(2);
          const discount_amnt = coupon_discount(props.coupon, amnt);

          props.couponAmount(discount_amnt.toFixed(2));

          return setDiscountAmount(discount_amnt.toFixed(2));
        } else {
          // Recurring Program
          const amnt = selected_plan.tuition_rate;
          props.couponAmount(coupon_discount(props.coupon, amnt).toFixed(2));

          return setDiscountAmount(
            coupon_discount(props.coupon, amnt).toFixed(2)
          );
        }
      } else if (props.type === PROP_EVENT) {
        const ticket_price =
          JSON.parse(props.program.tickets[props.selected]).ticket_price || 0;
        const amnt = coupon_discount(props.coupon, ticket_price);
        props.couponAmount(amnt.toFixed(2));
        return setDiscountAmount(amnt.toFixed(2));
      } else if (props.type === PROP_PARTY) {
        const parsed_plan = JSON.parse(props.program.tickets[props.selected]);
        const ticket_price =
          JSON.parse(props.program.tickets[props.selected]).ticket_price || 0;
        let addOnTotal = 0;
        if (parsed_plan.addOn) {
          Object.values(props.program.partyDetails.addOnAmounts).map(item => {
            addOnTotal += parseInt(item.price) * parseInt(item.amount);
          });
        }
        const amnt = coupon_discount(props.coupon, parseInt(ticket_price) + addOnTotal);
        props.couponAmount(amnt.toFixed(2));
        return setDiscountAmount(amnt.toFixed(2));
      } else if (props.type == PROP_MEMBERSHIP) {
        const parsed_plan = JSON.parse(props.program.prices[props.selected]);
        const amnt = coupon_discount(props.coupon, parsed_plan.price_per_term);
        props.couponAmount(amnt.toFixed(2));
        return setDiscountAmount(amnt.toFixed(2));
      } else if (props.type === PROP_ONLINE) {
        const selected_plan = getSelectedProgramPlan(
          props.program,
          props.selected
        );
        if (props.program_type === REGULAR_SEMESTER) {
          let total_price = parseFloat(selected_plan.total_price);
          if (selected_plan.addOnItems) {
            selected_plan.addOnItems.map(item => {
              total_price += parseFloat(item.value);
            });
          }
          console.log(
            "total price",
            total_price,
            parseFloat(selected_plan.total_price),
            tax
          );
          const d_amnt = coupon_discount(props.coupon, total_price);

          props.couponAmount(d_amnt.toFixed(2));

          return setDiscountAmount(d_amnt.toFixed(2));
        } else if (props.program_type === SINGLE_SESSIONS) {
          const total_price =
            parseFloat(
              selected_plan.total_price * props.singleSessionQuantity
            ) + tax;
          const d_amnt = coupon_discount(props.coupon, total_price);
          console.log("online single session values", total_price, tax, d_amnt);

          props.couponAmount(d_amnt.toFixed(2));

          return setDiscountAmount(d_amnt.toFixed(2));
        } else if (props.program_type === SEMESTER_DROPINS) {
          const amnt = parseFloat(
            (selected_plan.total_price / selected_plan.dayCount) *
            (1 + parseInt(selected_plan.dropin_interest) / 100) *
            props.selected_days_length
          ).toFixed(2);
          const discount_amnt = coupon_discount(props.coupon, amnt);
          props.couponAmount(discount_amnt.toFixed(2));

          return setDiscountAmount(discount_amnt.toFixed(2));
        } else {
          // Recurring Program
          const amnt = selected_plan.tuition_rate;
          props.couponAmount(coupon_discount(props.coupon, amnt).toFixed(2));

          return setDiscountAmount(
            coupon_discount(props.coupon, amnt).toFixed(2)
          );
        }
        // const total_price = parseFloat(selected_plan.total_price);
        // const d_amnt = coupon_discount(props.coupon, total_price);
        // return setDiscountAmount(amnt.toFixed(2));
      }
    }
  };

  const displayCouponValue = () => {
    if (props.coupon.type === PERCENT) {
      console.log("LOOK HERE", props.coupon.amount_off);
      return <>({parseFloat(props.coupon.amount_off).toFixed(0)}%)</>;
    } else if (props.coupon.type === COUPON_FIXED_AMOUNT) {
      console.log("LOOK HERE", props.coupon.amount_off);
      return <>(${parseFloat(props.coupon.amount_off).toFixed(2)})</>;
    }
  };
  useEffect(() => {
    const tax = props.taxes ? props.taxes / 100 : 0;
    calculateDiscountAmount(tax);
  }, [props.coupon]);

  useEffect(() => {
    console.log("taxes changed to", props.taxes);
    (async () => {
      const tax = props.taxes ? props.taxes / 100 : 0;
      console.log("setting tax to be", tax);
      await setTax(tax);
      await calculateDiscountAmount(tax);
    })();
  }, [props.taxes, props.coupon]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontSize: "14px"
        }}
      >
        Coupon Applied: {props.coupon.coupon_name} {displayCouponValue()}
      </p>
      <span style={{ fontSize: "14px" }}>- ${discountAmount}</span>
    </li>
  );
};

// fontSize: "10px"
// style={{ fontSize: "10px" }}
CheckoutCouponDisplay.propTypes = {
  coupon: PropTypes.shape({
    amount_off: PropTypes.string.isRequired,
    type: PropTypes.string
  }).isRequired,
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  program_type: PropTypes.string,
  selected_days_length: PropTypes.number,
  installments: PropTypes.bool,
  // program_type: PropTypes.oneOf([
  //   SEMESTER_DROPINS,
  //   INSTALLMENTS,
  //   REGULAR_SEMESTER,
  //   null
  // ]).isRequired,
  type: PropTypes.oneOf([
    PROP_PROGRAM,
    PROP_EVENT,
    PROP_MEMBERSHIP,
    PROP_ONLINE
  ]).isRequired
};

export default CheckoutCouponDisplay;
