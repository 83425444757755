import { useEffect } from "react";
import useMarketingIntegrations from "./useMarketingIntegrations";

const GoogleTagManager = () => {
    const { googleTagManagerId } = useMarketingIntegrations();

    useEffect(() => {
        if (!googleTagManagerId) {
            removeGTMScript();
            return;
        }

        injectGTMScript(googleTagManagerId);
    }, [googleTagManagerId]);

    const injectGTMScript = (id) => {
        if (document.getElementById("gtm-script")) return;

        const script = document.createElement("script");
        script.id = "gtm-script";
        script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');
        `;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.id = "gtm-noscript";
        noscript.innerHTML = `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `;
        document.body.appendChild(noscript);

        console.log("GTM script loaded and initialized:", id);
    };

    const removeGTMScript = () => {
        const script = document.getElementById("gtm-script");
        if (script) script.remove();
        const noscript = document.getElementById("gtm-noscript");
        if (noscript) noscript.remove();
        console.log("GTM script removed.");
    };

    return null;
};

export default GoogleTagManager;
