import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "../../../assets/css/componentSpecificCss/checkout.css";

import {
  getPromotionDiscountAmount,
  getEventPromotionDiscountAmount,
  getPartyPromotionDiscountAmount,
  getMembershipPromotionDiscountAmount,
  calculateProratedClasses,
  PROGRAM,
  ONLINE,
  EVENT,
  MEMBERSHIP,
  PARTY,
  PERCENT,
  FIXED_AMOUNT,
  promotion_discount,
  getSelectedProgramPlan,
  ONE_TIME,
  SEMESTER,
  SEMESTER_DROPINS,
  INSTALLMENTS,
  REGULAR_SEMESTER,
  SINGLE_SESSIONS
} from "./utils";

const CheckoutPromotionDisplay = props => {
  const [promotionAmount, setPromotionAmount] = useState("Loading");
  console.log("PROMO props", props);

  const calculatePromotionAmount = () => {
    if (props.promotion.promotion_type === PROGRAM) {
      const plan = getSelectedProgramPlan(props.program, props.selected);
      if (props.program_type === SINGLE_SESSIONS) {
        const total_price =
          parseFloat(plan.total_price) * parseInt(props.singleSessionQuantity);
        console.log("total_price", total_price);
        console.log("total_price", typeof total_price);

        const amnt = promotion_discount(props.promotion, total_price);

        props.promotionAmount(amnt.toFixed(2));

        return setPromotionAmount(amnt.toFixed(2));
      } else if (
        props.program_type === ONE_TIME ||
        props.program_type === SEMESTER ||
        props.program_type === REGULAR_SEMESTER
      ) {
        let total_price = parseFloat(plan.total_price);
        if (plan.isProrated) {
          const { totalSessions, sessionsPassed, amount } = calculateProratedClasses(props.program, plan.total_price);
          total_price = amount;
        }
        if (plan.addOnItems) {
          plan.addOnItems.map(item => {
            total_price += parseFloat(item.value);
          });
        }
        console.log("total_price", total_price);
        const amnt = promotion_discount(props.promotion, total_price);
        props.promotionAmount(amnt.toFixed(2));

        return setPromotionAmount(amnt.toFixed(2));
      } else if (props.program_type === SEMESTER_DROPINS) {
        const plan = getSelectedProgramPlan(props.program, props.selected);
        const amnt = parseFloat(
          plan.total_price *
          props.selected_days_length
        ).toFixed(2);

        props.promotionAmount(
          promotion_discount(props.promotion, amnt).toFixed(2)
        );

        return setPromotionAmount(
          promotion_discount(props.promotion, amnt).toFixed(2)
        );
      } else if (props.program_type === INSTALLMENTS) {
        const plan = getSelectedProgramPlan(props.program, props.selected);
        const installments_breakdown = plan.installments_breakdown;
        const total_price = plan.total_price;
        const installments_interest = parseInt(plan.installments_interest);

        const amnt = parseFloat(
          parseFloat(
            (total_price / installments_breakdown) *
            (props.installments ? 1 + installments_interest / 100 : 1) || 0
          )
        ).toFixed(2);

        if (plan.includeDeposit && plan.deposit && plan.deposit !== "0") {
          props.promotionAmount(
            promotion_discount(
              props.promotion,
              parseFloat(plan.deposit)
            ).toFixed(2)
          );
          return setPromotionAmount(
            promotion_discount(
              props.promotion,
              parseFloat(plan.deposit)
            ).toFixed(2)
          );
        } else {
          props.promotionAmount(
            promotion_discount(props.promotion, amnt).toFixed(2)
          );
          return setPromotionAmount(
            promotion_discount(props.promotion, amnt).toFixed(2)
          );
        }
      } else {
        const amnt = getPromotionDiscountAmount(
          props.promotion,
          getSelectedProgramPlan(props.program, props.selected)
        );
        console.log("amnt", amnt);

        props.promotionAmount(amnt.toFixed(2));

        return setPromotionAmount(amnt.toFixed(2));
      }
    } else if (props.promotion.promotion_type === EVENT) {
      const amnt = getEventPromotionDiscountAmount(
        props.promotion,
        props.program,
        props.selected
      );
      props.promotionAmount(amnt.toFixed(2));
      return setPromotionAmount(amnt.toFixed(2));
    } else if (props.promotion.promotion_type === PARTY) {
      const parsed_plan = JSON.parse(props.program.tickets[props.selected]);
      const ticket_price =
        JSON.parse(props.program.tickets[props.selected]).ticket_price || 0;
      let addOnTotal = 0;
      if (parsed_plan.addOn) {
        Object.values(props.program.partyDetails.addOnAmounts).map(item => {
          addOnTotal += parseInt(item.price) * parseInt(item.amount);
        });
      }
      
      const amnt = promotion_discount(
        props.promotion, parseFloat(ticket_price) + addOnTotal
      );
      props.promotionAmount(amnt.toFixed(2));
      return setPromotionAmount(amnt.toFixed(2));
    } else if (props.promotion.promotion_type === MEMBERSHIP) {
      const amnt = getMembershipPromotionDiscountAmount(
        props.promotion,
        props.program,
        props.selected
      );

      props.promotionAmount(amnt.toFixed(2));

      return setPromotionAmount(amnt.toFixed(2));
    } else if (props.promotion.promotion_type === ONLINE) {
      const plan = getSelectedProgramPlan(props.program, props.selected);
      if (props.program_type === SINGLE_SESSIONS) {
        const total_price =
          parseFloat(plan.total_price) * parseInt(props.singleSessionQuantity);
        console.log("total_price", total_price);
        console.log("total_price", typeof total_price);

        const amnt = promotion_discount(props.promotion, total_price);

        props.promotionAmount(amnt.toFixed(2));

        return setPromotionAmount(amnt.toFixed(2));
      } else if (
        props.program_type === ONE_TIME ||
        props.program_type === SEMESTER
      ) {
        let total_price = parseFloat(plan.total_price);
        if (plan.addOnItems) {
          plan.addOnItems.map(item => {
            total_price += parseFloat(item.value);
          });
        }
        const amnt = promotion_discount(props.promotion, total_price);
        props.promotionAmount(amnt.toFixed(2));

        return setPromotionAmount(amnt.toFixed(2));
      } else if (props.program_type === SEMESTER_DROPINS) {
        const plan = getSelectedProgramPlan(props.program, props.selected);
        const amnt = parseFloat(
          (plan.total_price / plan.dayCount) *
          (1 + parseInt(plan.dropin_interest) / 100) *
          props.selected_days_length
        ).toFixed(2);

        props.promotionAmount(
          promotion_discount(props.promotion, amnt).toFixed(2)
        );

        return setPromotionAmount(
          promotion_discount(props.promotion, amnt).toFixed(2)
        );
      } else {
        const amnt = getPromotionDiscountAmount(
          props.promotion,
          getSelectedProgramPlan(props.program, props.selected)
        );

        props.promotionAmount(amnt.toFixed(2));

        return setPromotionAmount(amnt.toFixed(2));
      }
    }
  };
  useEffect(() => {
    calculatePromotionAmount();
  }, []);

  const displayPromotionPercent = () => {
    if (props.promotion && props.promotion.discount_type === PERCENT) {
      return <>({props.promotion.discount_percent}%)</>;
    }
  };

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontSize: "14px"
        }}
      >
        {props.promotion.title} {displayPromotionPercent()}
      </p>
      <span style={{ fontSize: "14px" }}>- ${promotionAmount}</span>
    </li>
  );
};

CheckoutPromotionDisplay.propTypes = {
  promotion: PropTypes.shape({
    promotion_type: PropTypes.oneOf([PROGRAM, EVENT, MEMBERSHIP]).isRequired,
    discount_type: PropTypes.oneOf([PERCENT, FIXED_AMOUNT]).isRequired
  }).isRequired,
  program: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  program_type: PropTypes.string,
  selected_days_length: PropTypes.number,
  singleSessionQuantity: PropTypes.number
};

export default CheckoutPromotionDisplay;
