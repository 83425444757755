import { useEffect } from "react";
import useMarketingIntegrations from "./useMarketingIntegrations";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
    const location = useLocation();
    const { googleAnalyticsId } = useMarketingIntegrations();

    useEffect(() => {
        if (!googleAnalyticsId) {
            removeGA4Script();
            return;
        }
        injectGA4Script(googleAnalyticsId);
    }, [googleAnalyticsId]);
    useEffect(() => {
        if (!googleAnalyticsId || !window.gtag) return;
        window.gtag("event", "page_view", { page_path: location.pathname });

    }, [location.pathname]);

    const injectGA4Script = (id) => {
        if (document.getElementById("ga4-script")) return; // Prevent duplicate scripts

        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        script.id = "ga4-script";
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            window.gtag = gtag;
            gtag("js", new Date());
            gtag("config", id, { send_page_view: false });

            console.log("GA4 script loaded and initialized:", id);
        };
    };

    const removeGA4Script = () => {
        const script = document.getElementById("ga4-script");
        if (script) {
            script.remove();
            console.log("GA4 script removed.");
        }
        window.dataLayer = [];
        delete window.gtag;
    };

    return null;
};

export default GoogleAnalytics;
