import GoogleAnalytics from "./GoogleAnalytics";
import MetaPixel from "./MetaPixel";
import GoogleTagManager from "./GoogleTagManager";
import React from "react";
import TiktokPixel from "./TiktokPixel";

const MarketingIntegrations = () => {
    return (
        <>
            <GoogleAnalytics />
            <MetaPixel />
            <GoogleTagManager />
            <TiktokPixel />
        </>
    );
};

export default MarketingIntegrations;
