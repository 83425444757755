export function sendAddToCartEvent(item) {
    if (window.gtag) {
        window.gtag("event", "add_to_cart", {
            item_name: item.name
        });
        console.log("Sent add_to_cart event for", item.name);
    } else {
        console.log("Google Analytics is not loaded.");
    }
    if (window.fbq) {
        window.fbq("track", "AddToCart", {
            content_name: item.name
        });
        console.log("Sent AddToCart event for", item.name);
    } else {
        console.log("Meta Pixel is not loaded.");
    }
    if (window.ttq) {
        window.ttq.track("AddToCart", {content_name: item.name, content_id: item.name});
        console.log("Sent AddToCart event for", item.name);
    } else {
        console.log("Tiktok Pixel is not loaded.");
    }
}

export function sendCompletedCartCheckout() {
    if (window.gtag) {
        window.gtag("event", "completed_cart_checkout");
        console.log("Sent completed_cart_checkout event for");
    } else {
        console.log("Google Analytics is not loaded.");
    }
    if (window.fbq) {
        window.fbq("track", "InitiateCheckout");
        console.log("Sent InitiateCheckout event for");
    } else {
        console.log("Meta Pixel is not loaded.");
    }
    if (window.ttq) {
        window.ttq.track("CompletePayment");
        console.log("Sent CompletePayment event for");
    } else {
        console.log("Tiktok Pixel is not loaded.");
    }
}

export function sendApplicationSubmitted() {
    if (window.gtag) {
        window.gtag("event", "application_submitted");
    } else {
        console.log("Google Analytics is not loaded.");
    }
    if (window.fbq) {
        window.fbq("trackCustom", "application_submitted");
    } else {
        console.log("Meta Pixel is not loaded.");
    }
    if (window.ttq) {
        window.ttq.track("ApplicationSubmitted");
    } else {
        console.log("Tiktok Pixel is not loaded.");
    }
}



