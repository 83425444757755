import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


import { getSelectedProgramPlan, calculateProratedClasses } from "./utils";

const ProgramRegularSemester = props => {
  const [fee, setFee] = useState("Loading");
  const calculateFee = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    let itemAmount = plan.total_price;
    if (plan.isProrated) {
      console.log("sem props", props);
      const { totalSessions, sessionsPassed, amount } = calculateProratedClasses(props.program, plan.total_price);
      console.log("totalSessions", totalSessions);
      console.log("sessionsPassed", sessionsPassed);
      itemAmount = amount;
    }
    setFee(parseFloat(itemAmount).toFixed(2));
    props.itemAmount(parseFloat(itemAmount).toFixed(2));
  };
  useEffect(() => {
    calculateFee();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Semester Fee - Payable Now.
      </p>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${fee}</span>
    </li>
  );
};

ProgramRegularSemester.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramRegularSemester;
