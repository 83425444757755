import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  promotion_discount,
  PERCENT,
  getPromotionPercentAmount,
  FIXED_AMOUNT,
  getPromotionFixedAmount,
  getSelectedProgramPlan,
  calculateProratedClasses,
  isProgramPlanTaxEnabled
} from "./utils";

const ProgramRegularSemesterPromotionTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    let total_price = parseFloat(plan.total_price);
    if (plan.isProrated) {
      const { totalSessions, sessionsPassed, amount } = calculateProratedClasses(props.program, plan.total_price);
      total_price = amount;
    }
    if (plan.addOnItems) {
      plan.addOnItems.map(item => {
        total_price += parseFloat(item.value);
      });
    }

    if (props.installments) {
      const something1 = parseFloat(
        total_price *
          (props.installments
            ? 1 + parseInt(plan.installments_interest) / 100
            : 1) || 0
      );
      const something_about_tax = isProgramPlanTaxEnabled(
        props.program,
        props.selected
      )
        ? parseFloat(
            parseFloat(
              total_price *
                (props.installments
                  ? 1 + parseInt(plan.installments_interest) / 100
                  : 1) || 0
            ) *
              (plan.taxInfo[0].percentage / 100)
          )
        : 0;
      if (props.promotion.discount_type === PERCENT) {
        let tempTot = parseFloat(
          Math.max(
            something1 +
              something_about_tax -
              getPromotionPercentAmount(
                something1,
                props.promotion.discount_percent
              )
          )
        );
        setPreGiftTotal(tempTot);
        tempTot = giftBalance > tempTot ? 0 : tempTot - giftBalance;
        props.totalAmount(tempTot.toFixed(2));
        setTotal(tempTot.toFixed(2));
      } else if (props.promotion.discount_type === FIXED_AMOUNT) {
        let tempTot = parseFloat(
          Math.max(
            0,
            something1 +
              something_about_tax -
              getPromotionFixedAmount(props.promotion.discount_fixed)
          )
        );
        setPreGiftTotal(tempTot);
        tempTot = giftBalance > tempTot ? 0 : tempTot - giftBalance;
        props.totalAmount(tempTot.toFixed(2));

        setTotal(tempTot.toFixed(2));
      }
      return;
    } else {
      // not an installment
      if (
        props.promotion.discount_type === FIXED_AMOUNT &&
        plan.isTaxEnabled === true
      ) {
        let tot = 0;
        const discount_amount = promotion_discount(
          props.promotion,
          total_price
        );
        const taxRate = plan.isTaxEnabled
          ? parseFloat(plan.taxInfo[0].percentage / 100)
          : 0;
        const tax_amount = plan.isTaxEnabled
          ? Math.max(0, total_price - discount_amount) * taxRate
          : Math.max(0, total_price - discount_amount) * taxRate;

        const creditCardPercent = props.program.transaction_cost
          ? parseFloat(1.5) / 100
          : 1;
        const creditCardFee = props.program.transaction_cost
          ? Math.max(0.5, total_price - discount_amount + tax_amount) *
            creditCardPercent
          : 0;
        // const creditCardFee = 0;
        tot =
          Math.max(0, total_price - discount_amount) +
          tax_amount +
          creditCardFee;
        setPreGiftTotal(tot);
        console.log("giftBalance", giftBalance);
        tot = giftBalance > tot ? 0 : tot - giftBalance;

        if (total_price - discount_amount === 0) {
          tot = 0;
        }
        props.totalAmount(tot.toFixed(2));

        setTotal(tot.toFixed(2));
      } else if (
        props.promotion.discount_type === PERCENT &&
        plan.isTaxEnabled === true
      ) {
        let tot = 0;
        const discount_amount = promotion_discount(
          props.promotion,
          total_price
        );
        const taxRate = plan.isTaxEnabled
          ? parseFloat(plan.taxInfo[0].percentage / 100)
          : 0;
        const tax_amount = Math.max(0, total_price - discount_amount) * taxRate;

        const creditCardPercent = props.program.transaction_cost
          ? parseFloat(1.5) / 100
          : 1;
        const creditCardFee = props.program.transaction_cost
          ? Math.max(0.5, total_price - discount_amount) * creditCardPercent
          : 0;
        // const creditCardFee = 0;
        tot =
          Math.max(0, total_price - discount_amount) +
          tax_amount +
          creditCardFee;
        setPreGiftTotal(tot);
        console.log("giftBalance", giftBalance);
        tot = giftBalance > tot ? 0 : tot - giftBalance;

        if (total_price - discount_amount === 0) {
          tot = 0;
        }
        props.totalAmount(tot.toFixed(2));

        setTotal(tot.toFixed(2));
      } else {
        let tot = 0;
        const discount_amount = promotion_discount(
          props.promotion,
          total_price
        );
        const taxRate = plan.isTaxEnabled
          ? parseFloat(plan.taxInfo[0].percentage / 100)
          : 0;
        const tax_amount = Math.max(0, total_price - discount_amount) * taxRate;

        const creditCardPercent = props.program.transaction_cost
          ? parseFloat(1.5) / 100
          : 1;
        const creditCardFee = props.program.transaction_cost
          ? Math.max(0.5, total_price - discount_amount) * creditCardPercent
          : 0;
        // const creditCardFee = 0;
        tot =
          Math.max(0, total_price - discount_amount) +
          tax_amount +
          creditCardFee;
        setPreGiftTotal(tot);
        console.log("giftBalance", giftBalance);
        tot = giftBalance > tot ? 0 : tot - giftBalance;

        if (total_price - discount_amount === 0) {
          tot = 0;
        }
        console.log("LOOK HERE", tot);
        props.totalAmount(tot.toFixed(2));

        setTotal(tot.toFixed(2));
      }
    }
  };

  useEffect(() => {
    calculateTotal();
  }, []);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600
        }}
      >
        Total
      </p>
      <span style={{ fontWeight: "bold" }}>${total}</span>
    </li>
  );
};

ProgramRegularSemesterPromotionTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  installments: PropTypes.number.isRequired,
  promotion: PropTypes.object.isRequired
};

export default ProgramRegularSemesterPromotionTotal;
